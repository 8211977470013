import React, { FC } from 'react'
import styled from 'styled-components'
import { textFont } from '../styles/typography'
import { PageLayout } from '../layouts/PageLayout'
import { Title } from '../components/Title'
import { theme } from '../theme'
import heroImage from '../images/hero/hero-contact.jpg'
import * as Hero from '../components/Hero'

const AddressList = styled.div`
  display: flex;
  ${textFont('body')}
  margin-top: ${theme.spacing.extraLoose};

  > div {
    margin-left: ${theme.spacing.extraLoose};

    &:first-child {
      margin-left: 0;
    }
  }

  strong {
    ${textFont('body', 'bold')}
  }

  @media (min-width: ${props => props.theme.breakpoint.mediumScreen}) {
  }
`

const ContactPage: FC = () => {
  return (
    <PageLayout>
      <Title title="Contact us - Bueno" />

      <Hero.Wrapper src={heroImage}>
        <Hero.LayoutContent>
          <Hero.LayoutHeading>
            <Hero.Heading>Get in Touch</Hero.Heading>
            <Hero.Subheading>
              Visit our office or give us a call
              <br />
              <strong>1300 130 366</strong>
              <a
                href="mailto:hello@buenosystems.com.au?Subject=Enquiry from website"
                target="_blank"
                rel="noreferrer"
                style={{
                  marginLeft: `${theme.spacing.extraLoose}`,
                  fontWeight: 'bold'
                }}
              >
                hello@buenosystems.com.au
              </a>
              <AddressList>
                <div>
                  <strong>Melbourne (HQ)</strong>
                  <p>
                    353 Exhibition Street
                    <br />
                    Melbourne, VIC 3000
                  </p>
                </div>
                <div>
                  <strong>Sydney</strong>
                  <p>
                    Level 8, 17-19 Bridge Street
                    <br />
                    Sydney, NSW 2000
                  </p>
                </div>
              </AddressList>
            </Hero.Subheading>
          </Hero.LayoutHeading>
        </Hero.LayoutContent>
      </Hero.Wrapper>

      {/* <FormWrap>
        <FormContainer>
          <FormColumn>
            <SubHeading>Sales</SubHeading>
            <p>
              Ready to talk business?
              <br />
              <a href="#">Contact our sales team</a>
            </p>
          </FormColumn>

          <FormColumn>
            <SubHeading>Partners</SubHeading>
            <p>
              We’re here for you.
              <br />
              <a href="#">Contact us</a>
            </p>
          </FormColumn>

          <FormColumn>
            <SubHeading>Support</SubHeading>
            <p>
              Existing customer?
              <br />
              <a href="#">Contact support</a>
            </p>
          </FormColumn>

          <FormColumn isDesktop>
            Anything else drop us an <a href="#">email</a> or complete the form.
          </FormColumn>
        </FormContainer>

        <FormContainer isRight>Form</FormContainer>
      </FormWrap> */}
    </PageLayout>
  )
}

export default ContactPage
